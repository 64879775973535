import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Image,
  extendTheme,
  Flex,
} from '@chakra-ui/react';

import { motion } from 'framer-motion';

// import { ColorModeSwitcher } from './ColorModeSwitcher';

function App() {
  // State to control text visibility on hover
  const [isHovered, setIsHovered] = useState(false);

  const theme = extendTheme({
    styles: {
      global: () => ({
        body: {
          bg: 'linear-gradient(69.9deg, rgb(76, 79, 106) 3.2%, rgb(68, 74, 113) 97.6%);',
          color: 'white',
        },
      }),
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Flex alignItems="center" justifyContent="center">
          <Grid minH="100vh" p={3}>
            <Flex
              width="90vh"
              direction="column"
              p={12}
              rounded={5}
              alignItems="center"
            >
              {/* Motion div for the logo */}
              <motion.div
                initial={{ y: -150, x: 0, opacity: 0, scale: 0.3 }}
                animate={{ y: 0, x: 0, opacity: 1, scale: 1 }}
                transition={{
                  ease: [0.42, 0, 0, 1.08],
                  duration: 1.5,
                  delay: 0.2,
                  type: 'spring',
                }}
                whileHover={{ y: 20, scale: 1.2 }} // Move slightly up on hover
                onHoverStart={() => setIsHovered(true)} // Show text on hover
                onHoverEnd={() => setIsHovered(false)} // Hide text when hover ends
              >
                <Image src="./images/mainLogo.webp" draggable="false" />
              </motion.div>

              {/* Ensure smooth layout changes */}
              <motion.div layout>
                {/* New Text component with motion */}
                {isHovered && (
                  <motion.div
                    initial={{ y: -50, opacity: 0 }} // Start position and opacity
                    animate={{ y: 0, opacity: 1 }} // Animate into view
                    exit={{ y: -50, opacity: 0 }} // Exit animation
                    transition={{
                      duration: 0.7,
                      ease: [0.42, 0, 0, 1.08],
                      type: 'spring',
                    }}
                  >
                    <Text fontSize={'4xl'} mt={4}>
                      DevMarket, the world's Developer Marketplace.
                    </Text>
                  </motion.div>
                )}

                {/* Regular content */}
                <VStack spacing={8} mt={isHovered ? 4 : 0}>
                  <Text fontSize={'3xl'}>
                    We're launching <Code fontSize="3xl">soon</Code>, stay
                    tuned.
                  </Text>
                  <Text>
                    In the meantime, why not check out something{' '}
                    <Link
                      color="purple.200"
                      href="https://savvydev.me/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      pretty cool
                    </Link>
                    ?
                  </Text>
                </VStack>
              </motion.div>
            </Flex>
          </Grid>
        </Flex>
      </Box>
    </ChakraProvider>
  );
}

export default App;
